export default {
  authorBackground: 'white',
  childWidth: 'unset',
  lexAlign: 'center',
  marginLeft: '-1rem',
  size: '4em',
  alignment: 'left',
  linkColor: 'var(--digi-blue-700)',
  clipPath: 'circle(35% at 50% 50%)',
  backgroundContainerDisplay: 'none',
};
