export default {
  paddingTop: '1.2rem',
  paddingTopDesktop: '4rem',
  marginTop: '0',
  linkPadding: '0.1rem 0',
  fontSize: '0.9rem',
  titleFontSize: '0.9rem',
  infoPaddingTop: '2rem',
  textDecoration: 'none',
  pTagColor: 'var(--advisa-wallet-100)',
  columnMarginTop: '2rem',
  footerTextMarginTop: '1.5rem',
  fontWeightTitle: 'bold',
  backgroundColor: 'var(--digifinans-wallet-1000)',
  linkColor: 'var(--advisa-wallet-100)',
  textColor: 'var(--digifinans-wallet-300)',
  fontFamily: 'var(--font-family)',
  logoSize: '9.4rem',
  columnsPaddingTopMobile: '3rem',
};
