export default {
  mobileMenuBg: 'yellow',
  mobileMenuButtonColor: 'var(--digi-blue-500)',
  menuMarginTopMobile: '0.7rem',
  buttonSvgWidthHeight: '1.5rem',
  backgroundColor: 'var(--digi-blue-100)',
  linkColor: 'var(--digi-blue-500)',
  fontFamily: 'var(--fontFamily)',
  textBgColor: 'var(--digi-blue-500)',
  overlayBackgroundColor: 'var(--digi-blue-100)',
  activeLinkColor: 'var(--mbc)',
  loginButtonBackgroundColor: 'var(--mbc)',
  loginButtonTextColor: 'var(--white)',
  phoneNumberIconColor: 'var(--digi-blue-500)',
  loginButtonBorderRadius: '30px',
};
