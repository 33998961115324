export default {
  color: 'var(--mbc)',
  gridColumnMobile: 'repeat(2, 1fr)',
  creditorsColumnsGrid: 'repeat(6, 1fr)',
  creditorsColumns: 6,
  borderRadius: '28px',
  borderWidth: '2px',
  borderColor: 'var(--mbc)',
  margin: 'auto',
  imageWidth: '100%',
  backgroundColor: 'var(--white)',
  buttonBackgroundColor: 'var(--transparent)',
  borderStyle: 'solid',
  textDecExpandBut: 'none',
  expButPadding: '0.8rem',
  displayExpButton: 'block',
  iconColor: 'var(--mbc)',
  expandButtonFont: '0.9rem',
  cardPadding: '1.5rem',
};
