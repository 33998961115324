export default {
  questionColor: 'var(--digi-blue-700)',
  faqIconColor: 'var(--digi-blue-700)',
  borderRadius: '3px',
  backgroundColorFAQ: 'var(--white)',
  margin: '1.5rem 0',
  boxShadow: 'none',
  questionPadding: '1rem',
  questionJustification: 'space-between',
  answerPadding: '0.5rem 1rem 1rem',
  bulletColor: '#000',
  order: '1',
  FAQAnswerMargin: '0',
  fontSize: '2rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  expandButtonBorderRadius: '28px',
  borderWidth: '2px',
  borderColor: 'var(--black)',
  expandButtonMargin: 'auto',
  paddingTop: '-2rem',
  titleSizeDownLaptop: '1.6rem',
  h1FontWeight: 'var(--has-font-weight)',
  svgTransform: '180deg',
  originalSvgTransForm: '0deg',
  buttonBackground: 'var(--digi-blue-400)',
  borderStyle: 'none',
  buttonTextColor: 'var(--white)',
  border: '1px solid var(--dark-teal-20)',
  transformBorder: '1px solid var(--digi-blue-400)',
  answerFontWeight: 'var(--has-no-font-weight)',
  svgOrder: '1',
  answerFontSize: '1rem',
};
